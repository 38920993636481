@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";
@import "~rc-time-picker/assets/index.css";

.react-grid-item {
	touch-action: auto !important;
}

button {
	background-color: #88bb3c;
	border: none;
	color: white;
	padding: 10px;
	font-size: 14px;
	cursor: pointer;
	border-radius: 5px;
}

button:hover {
	background-color: #6fa53c;
}
